<template>
  <div style="margin: 0 50px">
    <div class="about-ky-titl-a">
      <h2>科汇简介</h2>
      <!-- <h5>INTRODUCTION</h5> -->
      <div><img src="../../../assets/img/botton/jj.png" alt="" /></div>
    </div>
    <p class="about-ky-intro-p" v-html="xq.content"></p>

    
  </div>
</template>

<script>
import { AboutList } from "../../../common/js/api";
export default {
  data() {
    return {
      xq: {},
    };
  },

  created() {
    this.getAbout();
  },
  methods: {
    // 科汇简介
    getAbout() {
      AboutList({})
        .then((res) => {
          this.xq = res.data;
        })
        .catch(() => {});
    },
  },
};
</script>


<style lang="less" scoped>
// 标题
.about-ky-titl-a {
  padding-bottom: 30px;
  h2 {
    position: relative;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 10px;
  }
  h2:after {
    position: absolute;
    content: "";
    left: 50%;
    bottom: -10px;
    margin-left: -30px;
    width: 54px;
    height: 2px;
    background: #3892eb;
  }
  div {
    width: 225px;
    height: 20px;
    margin: 0 auto;
    margin-top: 20px;
  }
  //   h5 {
  //     margin-top: 20px;
  //     font-family: Arial;
  //     letter-spacing: 14px;
  //     color: #686868;
  //     text-align: center;
  //   }
}
// 内容
.about-ky-intro-p {
  text-indent: 2em;
  font-size: 14px;
  line-height: 30px;
  color: #686868;
  font-family: "Microsoft Yahei";
  margin-bottom: 20px;
}
</style>
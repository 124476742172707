<template>
  <div style="margin: 0 50px">
    <div class="about-ky-titl-a">
      <h2>科汇云大事记</h2>
      <div><img src="../../../assets/img/botton/kh.png" alt="" /></div>
    </div>
    <div class="about-ky-things-wrap">
      <div class="about-ky-things" v-for="(item,index) in NewEvent" :key="index">
        <div class="year">{{item.name}}</div>
        <div class="about-ky-things-cont-wrap clearfix" v-for="(i, index) in item.arr" v-if="index % 2 == 0">
          <div class="about-ky-things-cont cont-right right clearfix"  >
            <p class="right"  v-html="i.content">
               
            </p>
            <div class="time">
              <span>{{i.title}}</span>
            </div>
          </div>
          <div  v-if="index + 1 < item.arr.length"
            class="about-ky-things-cont cont-left left clearfix"
            style="margin-top: 90px"
          >
            <div class="time dark-bg">
              <span>{{item.arr[index+1].title}}</span>
            </div>
            <p class="left" v-html="item.arr[index+1].content">
              
            </p>
          </div>
        </div>
      </div>

      <div class="about-ky-things">
        <div class="img">
          <img src="../../../assets/img/botton/bg-16.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventList } from "../../../common/js/api";
export default {
  data() {
    return { Event: [], NewEvent: [] };
  },

  created() {
    this.getEvent();
  },
  methods: {
    // 评价
    getEvent() {
      EventList({})
        .then((res) => {
          this.Event = res.data;
          let Arr = [];
          for (let i = 0; i < this.Event.length; i++) {
            if (Arr.indexOf(this.Event[i].name) === -1) {
              this.NewEvent.push({
                name: this.Event[i].name,
                arr: [this.Event[i]],
              });
              Arr.push(this.Event[i].name);
            } else {
              for (let j = 0; j < this.NewEvent.length; j++) {
                if (this.Event[i].name == this.NewEvent[j].name) {
                  this.NewEvent[j].arr.push(this.Event[i]);
                  break;
                }
              }
            }
          }
          console.log(this.NewEvent);
        })
        .catch(() => {});
    },
  },
};
</script>


<style lang="less" scoped>
// 标题
.about-ky-titl-a {
  padding-bottom: 30px;
  h2 {
    position: relative;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 10px;
  }
  h2:after {
    position: absolute;
    content: "";
    left: 50%;
    bottom: -10px;
    margin-left: -30px;
    width: 54px;
    height: 2px;
    background: #3892eb;
  }
  div {
    width: 360px;
    height: 20px;
    margin: 0 auto;
    margin-top: 20px;
  }
}

// 内容
.about-ky-things-wrap:after {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  bottom: 0;
  border: 1px dotted #959595;
}
.about-ky-things-wrap {
  position: relative;
  .year {
    position: relative;
    margin: 20px auto 0;
    width: 90px;
    height: 90px;
    line-height: 90px;
    font-size: 24px;
    text-align: center;
    color: #000;
    border: 3px solid rgba(56, 146, 235, 100);
    background-color: #fff;
    border-radius: 50%;
    z-index: 1;
  }
  .about-ky-things-cont {
    position: relative;
    width: 295px;
    background-color: #fff;
    // border: solid 1px #e5e5e5;
    p {
      padding: 10px;
      width: 225px;
      line-height: 20px;
      color: #333;
      box-shadow: 0px 2px 6px 0px rgba(90, 90, 90, 0.4);
      font-size: 14px;
    }
    .time {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      display: table;
      width: 50px;
      height: 100%;
      text-align: center;
      color: #fff;
      background: #3892eb;
      span {
        display: table-cell;
        vertical-align: middle;
      }
    }
    .time:before {
      position: absolute;
      content: "";
      top: 27%;
      right: -48px;
      width: 20px;
      height: 20px;
      background: url(../../../assets/img/botton/bg-15.svg);
      z-index: 1;
    }
    .time:after {
      position: absolute;
      content: "";
      top: 30%;
      border-left: 20px solid #3892eb;
      border-right: 20px solid transparent;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }
  }

  .cont-right {
    margin-left: 90px;
    .time {
      left: 0;
    }
    .time:before {
      position: absolute;
      content: "";
      top: 54%;
      left: -45px;
      width: 20px;
      height: 20px;
      background: url(../../../assets/img/botton/bg-15.svg);
    }
    .time:after {
      position: absolute;
      content: "";
      top: 60%;
      left: -70px;
      border-left: 50px solid transparent;
      border-right: 20px solid #3892eb;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }
  }
  .img {
    position: relative;
    margin: 30px auto;
    width: 80px;
    height: 80px;
    text-align: center;
    z-index: 1;
  }
}
</style>

<template>
  <div>
      <!-- 标题 -->
    <div class="about-ky-titl-a">
      <h2>联系我们</h2>
      <div><img src="../../../assets/img/botton/lx.png" alt="" /></div>
    </div>
    <!-- 地图 -->
    <div class="main about-ky-main about-ky-map-wrap">
      <div class="about-ky-map clearfix">
        <div class="about-ky-map-l left" >
         <h4>
            <span>科汇云运营中心</span>
          </h4>
          <p v-html="xq.content"></p>
          <!-- <p>地址：某某省某某市某某区某某某某某某某</p>
          <p>邮编：361026</p>
          <p>客服电话：400-649-1633</p>
          <p>
            <span>工作日：8:30~21:00</span><span> 休息日：8:30~17:30</span>
          </p>
          <p>传真：0592-3908789</p>
          <p>客服邮箱：KF@1633.COM</p> -->
          
        </div>
        <div class="about-ky-map-r left"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ContactList } from "../../../common/js/api";
export default {
  data() {
    return {
      xq:{}
    };
  },

  created() {
    this.getContact();
  },
  methods: {
    // 联系我们详情
    getContact() {
      ContactList({})
        .then((res) => {
			console.log('地址',res);
           this.xq = res.data
        })
        .catch(() => {});
    },
  },
};
</script>


<style lang="less" scoped>
// 标题
.about-ky-titl-a {
  padding-bottom: 30px;
  h2 {
    position: relative;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 10px;
  }
  h2:after {
    position: absolute;
    content: "";
    left: 50%;
    bottom: -10px;
    margin-left: -30px;
    width: 54px;
    height: 2px;
    background: #3892eb;
  }
  div {
    width: 182px;
    height: 20px;
    margin: 0 auto;
    margin-top: 20px;
  }
}
// 地图
.about-ky-map-wrap {
  margin-bottom: 70px;
  padding: 35px 55px;
  height: 320px;
  background: url(../../../assets/img/botton/2.png) no-repeat;
  .about-ky-map {
    width: 700px;
      background: url(../../../assets/img/botton/t.png) no-repeat;
    .about-ky-map-l {
      padding: 21px 21px;
      width: 50%;
      height: 270px;
      background-color: #0b6bc5;
      white-space: pre-line;
      h4 {
        margin-bottom: 30px;
        font-size: 20px;
        font-weight: bold;
        color: #fff;
      }
      p {
        font-size: 14px;
        // line-height: 28px;
        color: #fff;
      }
    }
    .about-ky-map-r {
      width: 50%;
      height: 270px;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
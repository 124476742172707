<template>
  <div>
    <div class="about-ky-titl-a">
      <h2>招聘岗位</h2>
      <div><img src="../../../assets/img/botton/zp.png" alt="" /></div>
    </div>
    <!-- 搜索 -->
    <div class="search-bar clearfix">
      <el-input
        placeholder="请输入内容"
        v-model="keyword"
        class="input-with-select" clearable  @clear="getJoin"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="getJoin"
        ></el-button>
      </el-input>
    </div>
    <!-- 筛选 -->
    <p class="zwlx">
      职位类型：
      <span
        v-for="(t, index) in type"
        :key="index"
        @click="toggle(index, t.id)"
        :class="{ on: active === index }"
        >{{ t.name }}</span
      >
     </p>
    <!-- 表格 -->
    <el-table :data="tableData" style="width: 100%">
      <el-table-column label="职位名称" prop="name" align="center">
      </el-table-column>
      <el-table-column label="学历" prop="educations" align="center">
      </el-table-column>
      <el-table-column label="职业类型" prop="types" align="center">
      </el-table-column>
      <el-table-column label="工作地点" prop="place" align="center">
      </el-table-column>
      <el-table-column label="发布时间" prop="created_at" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.created_at.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="招聘人数" prop="people" align="center">
      </el-table-column>
      <el-table-column type="expand" align="center">
        <template slot-scope="props">
          <div>
            <div class="inline">
              <span>工作年限：{{ props.row.years }}</span
              ><span>薪资范围：{{ props.row.salarys }}</span>
            </div>

            <div class="wrap-div d1">
              <span>任职要求：</span>
              <ul v-html="props.row.content"></ul>
            </div>
            <div class="wrap-div d2">
              <span>岗位职责：</span>
              <ul>
                {{
                  props.row.details
                }}
              </ul>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <pagination
        style="padding: 20px 0"
        class="pages"
         :total="total"
        layout="total,  prev, pager, next, jumper"
        :page.sync="listQuery.page"
        :limit.sync="listQuery.limit"
        @pagination="getJoin"
      />
    </div>
  </div>
</template>

<script>
import { JoinList, AType } from "../../../common/js/api";
import Pagination from "@/common/Pagination";
export default {
  components: { Pagination },
  data() {
    return {
      active: 0,
      keyword: "",
      tableData: [],
      type: [],
      types: 0,
      total: 0, //总条目数
      listQuery: {
        limit: 10,
        page: 1,
      },
    };
  },

  created() {
    this.getJoin();
    this.gettype();
  },
  methods: {
    // 职位类型
    gettype() {
      AType({})
        .then((res) => {
          // console.log(res);
          var b = { name: "全部" };
          res.data.unshift(b);
          this.type = res.data;
        })
        .catch(() => {});
    },

    toggle(i, id) {
      this.active = i;
      this.types = id;
      this.getJoin();
    },
    // 职位列表
    getJoin() {
      JoinList({
        page: this.listQuery.page,
        limits: this.listQuery.limit,
        name: this.keyword,
        type: this.types,
      })
        .then((res) => {
           this.tableData = res.data.data;
          this.total = res.data.total;
        })
        .catch(() => {});
    },
  },
};
</script>


<style lang="less" scoped>
.about-ky-titl-a {
  padding-bottom: 30px;
  h2 {
    position: relative;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 10px;
  }
  h2:after {
    position: absolute;
    content: "";
    left: 50%;
    bottom: -10px;
    margin-left: -30px;
    width: 54px;
    height: 2px;
    background: #3892eb;
  }
  div {
    width: 182px;
    height: 20px;
    margin: 0 auto;
    margin-top: 20px;
  }
}
// 搜索
.search-bar {
  margin: 0 auto;
  width: 369px;
  /deep/.el-input-group {
    border: solid 1px #3892eb;
    border-radius: 5px;
  }
  /deep/.el-input-group__append,
  .el-input-group__prepend {
    background: #3892eb;
    border: 1px solid #3892eb;
    border-radius: 0;
    color: #fff;
  }
}
// 筛选
p.zwlx {
  padding: 24px 45px;
  color: #2c2c2c;
  font-weight: bold;
  font-size: 14px;
  span {
    display: inline-block;
    vertical-align: middle;
    padding: 4px 15px;
    margin: 0 20px;
    font-weight: normal;
  }
  .on {
    color: #fff;
    background-color: #3892eb;
  }
}
// 表格
/deep/.el-table th,
.el-table tr {
  background: #deefff;
  color: #3892eb;
}
/deep/.el-table__expand-icon > .el-icon {
  color: #3892eb;
  font-weight: 700;
  font-size: 16px;
}
.inline {
  padding: 10px 20px 20px;
  line-height: 1;
  font-size: 14px;
  color: #1988fa;
  border-bottom: dashed 1px #dddde6;
  span {
    display: inline-block;
    margin-right: 100px;
  }
}
.wrap-div {
  padding: 20px 20px 0;
  font-size: 14px;
  line-height: 22px;
  color: #29292f;
  ul {
    max-width: 580px;
    display: inline-block;
    vertical-align: top;
  }
}
 
</style>
<template>
  <div class="about-ky-wrap">
    <div class="titie">
      <h2>{{ tabs[active].type }}</h2>
      <div><img :src="tabs[active].img" alt="" /></div>
    </div>
    <div class="main about-ky-main clearfix">
      <div class="about-ky-nav-wrap left">
        <ul class="leftNav">
          <li
            v-for="(tab, index) in tabs"
            :key="index"
            @click="toggle(index, tab.view)"
            :class="{ on: active === index }"
          >
            <span>{{ tab.type }}</span>
          </li>
        </ul>
      </div>
      <div class="about-ky-content-wrap left">
        <component :is="current"></component>
      </div>
    </div>
  </div>
</template>

<script>
import about from "./about.vue";
import contact from "./contact.vue";
import join from "./join.vue";
import events from "./events.vue";
import evaluation from "./evaluation.vue";

export default {
  components: {
    // 声明子组件
    about,
    contact,
    join,
    events,
    evaluation,
  },
  data() {
    return {
      active: 0,
      current: "about",
      ID: "",
      tabs: [
        {
          id: 4,
          type: "关于科汇",
          view: "about",
          img: require("../../../assets/img/botton/jj1.png"),
          index: 0,
        },
        {
          id: 5,
          type: "联系我们",
          view: "contact",
          img: require("../../../assets/img/botton/lx1.png"),
          index: 1,
        },
        {
          id: 6,
          type: "科汇大事记",
          view: "events",
          img: require("../../../assets/img/botton/kh1.png"),
          index: 2,
        },
        {
          id: 7,
          type: "诚聘英才",
          view: "join",
          img: require("../../../assets/img/botton/zp1.png"),
          index:3,
        },

        {
          id: 8,
          type: "领导关怀",
          view: "evaluation",
          img: require("../../../assets/img/botton/pj1.png"),
          index: 4,
        },
      ],
    };
  },

  created() {
    this.ID = this.$route.query.id;
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.ID == this.tabs[i].id) {
        this.active = this.tabs[i].index;
        this.current = this.tabs[i].view;
      }
    }
  },
  methods: {
    toggle(i, v) {
      this.active = i;
      this.current = v;
    },
  },
};
</script>


<style lang="less" scoped>
.about-ky-wrap {
	margin-top: 135px;
  padding-top: 100px;
  background: url(../../../assets/img/zhengfuyun/1.png) top center no-repeat;
  .titie {
    text-align: center;
    margin-bottom: 110px;
    font-size: 48px;
    color: #fff;

    h2 {
      font-weight: 700;
    }
    img {
      width: auto;
      height: auto;
    }
  }
  .about-ky-main {
    padding: 20px;
    background-color: #fff;
    border-radius: 4px 4px 0 0;
    position: relative;
    margin: 0 auto;
    width: 1180px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
    margin-bottom: 40px;
    .about-ky-nav-wrap {
      width: 230px;
      li {
        // margin-bottom: 20px;
        width: 100%;
        height: 72px;
        text-align: center;
        cursor: pointer;
        border-left: 6px solid transparent;
        border-bottom: 1px solid #eee;
        span {
          display: block;
          //   padding: 16px 20px;
          width: 100%;
          height: 100%;
          font-size: 18px;
          line-height: 72px;
          color: #101010;
          font-weight: 700;
        }
      }
      .on {
        border-left: 6px solid #3892eb;
        background: #eee;
        span {
          color: #3892eb;
        }
      }
    }
    .about-ky-content-wrap {
      margin-left: 13px;
      padding: 20px 50px;
      width: 897px;
    }
  }
}
@media screen and (max-width: 1200px) {
	.about-ky-wrap{
		margin-top: 15rem;
	}
	.about-ky-wrap .about-ky-main{
		width: 98%;
	}
	.about-ky-wrap .about-ky-main .about-ky-nav-wrap{
		width: 100%;
	}
	.leftNav{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
	.about-ky-wrap .about-ky-main .about-ky-nav-wrap li{
		width: 30%;
	}
	.about-ky-wrap .about-ky-main .about-ky-nav-wrap li span{
		font-size: 1.6rem;
	}
	.about-ky-wrap .about-ky-main .about-ky-content-wrap{
		width: 100%;
		padding: 3rem 0;
		margin: 0;
	}
	/deep/.about-ky-titl-a h2{
		font-size: 1.8rem;
	}
	/deep/.about-ky-titl-a div{
		width: 18rem;
		height: auto;
	}
	/deep/.about-ky-intro-p{
		font-size: 1.4rem;
		line-height: 2.5rem;
	}
}
</style>

<template>
  <div>
    <!-- 标题 -->
    <div class="about-ky-titl-a">
      <h2>评价与肯定</h2>
      <div><img src="../../../assets/img/botton/pj.png" alt="" /></div>
    </div>
    <!-- 评价 -->
    <div>
      <div class="eva_box clearfix" v-for="(l, index) in Leader" :key="index">
        <div class="eva_box_pht left">
          <img :src="urlImg + l.img" alt="" />
        </div>
        <div class="eva_box_msg left">
          <p class="name">{{ l.name }}<span>评价</span></p>
          <div class="box1_msg" v-html="l.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Leader } from "../../../common/js/api";
import { urlImg } from "@/common/js/url";
export default {
  data() {
    return {
      Leader: [],
    };
  },

  created() {
    this.urlImg = urlImg();
    this.getLeader();
  },
  methods: {
    // 评价
    getLeader() {
      Leader({})
        .then((res) => {
          this.Leader = res.data.data;
        })
        .catch(() => {});
    },
  },
};
</script>


<style lang="less" scoped>
// 标题
.about-ky-titl-a {
  padding-bottom: 30px;
  h2 {
    position: relative;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 10px;
  }
  h2:after {
    position: absolute;
    content: "";
    left: 50%;
    bottom: -10px;
    margin-left: -30px;
    width: 54px;
    height: 2px;
    background: #3892eb;
  }
  div {
    width: 167px;
    height: 20px;
    margin: 0 auto;
    margin-top: 20px;
  }
}

.eva_box {
    margin-bottom: 30px;
  .eva_box_pht img {
    width: 120px;
    height: 120px;
    margin: 0 24px;
    border-radius: 50%;
    box-shadow: 0px 2px 6px 0px rgba(56, 146, 235, 40);
  }
  
  .eva_box_msg .name {
    font-size: 14px;
    color: #3892eb;
    line-height: 24px;
  }
  .eva_box_msg span {
    font-size: 14px;
    color: #333333;
    line-height: 20px;
  }
  .box1_msg {
    margin-top: 15px;
    width: 610px;
    font-size: 14px;
    line-height: 20px;
    color: #3e3a39;
    padding: 5px;
    border: 1px solid rgba(56, 146, 235, 100);
    box-shadow: 0px 2px 6px 0px rgba(56, 146, 235, 20);
    border-radius: 4px;
    text-indent: 2em;
    
  }
}
</style>